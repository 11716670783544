<template>
  <SelaFieldError v-slot="{ hasError }" :name="error" class="mb-3">
    <label :for="id">
      <span
        class="text-sm font-semibold text-sela-light"
        :class="{ 'text-red-400': hasError }"
      >
        {{ t('phone') }}
      </span>
    </label>
    <div class="mt-2 bg-bg_color">
      <vue-tel-input
        :id="id"
        v-model="phone"
        :class="{ error: hasError }"
        dir="ltr"
        mode="international"
      />
    </div>
  </SelaFieldError>
</template>

<script setup lang="ts">
// @ts-ignore
import { VueTelInput } from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'

const props = defineProps({
  id: { type: String, required: true },
  label: { type: String, default: null },
  modelValue: { type: String, default: null },
  error: { type: String, default: '' }
})

const emit = defineEmits(['update:modelValue'])

const { t } = useI18n()

const phone = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
})
</script>
